<template>
  <div class="top-equipment-chart-container">
    <div class="control">
      <HomeTitle :title="'Top品牌'" :borderColor="'#008c8c'" />
      <div class="app-select-box">
        <m-select-more
          style="width: 140px; margin-right: 5px"
          size="small"
          :showLabel="false"
          v-model="query.appIdList"
          :allData="appList"
          :searchById="true"
          :hasIcon="true"
          :showId="true"
          width="450px"
          label="应用"
          @change="changeAppIds"
          :disabled="+isMock === 1"
        />
      </div>
    </div>
    <a-spin :spinning="spinning">
      <div class="chart" ref="chart">
        <div v-show="hasData" class="chart-box">
          <Chart :option="option" />
        </div>
        <div class="empty" v-show="!hasData"></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../../components/homeTitle.vue'
import option from './options'
import mixGetList from '@/mixins/getList'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
import { mockData } from './mockData'
import Chart from '@/components/common/Chart'
import { getAppBrandData } from '@/api/dashboard'
import { graphic } from 'echarts'
export default {
  mixins: [mixGetList],
  components: { HomeTitle, Chart },
  data () {
    return {
      spinning: false,
      query: {
        appIdList: []
      },
      option: {},
      hasData: true
    }
  },
  props: {
    modelType: {
      default: 0,
      type: Number
    }
  },
  watch: {
    isMock: {
      handler () {
        this.getChartsData()
      }
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  created () {
    this.option = JSON.parse(JSON.stringify(option))
    this.getChartsData()
    this.getAppList()
  },
  mounted () {},
  methods: {
    changeAppIds (e) {
      this.getChartsData()
    },
    async getChartsData () {
      this.spinning = true
      let resp
      if (+this.isMock) {
        resp = mockData
      } else {
        resp = await getAppBrandData(this.query)
      }
      const { data = {} } = resp
      const { otherBrand = [], topBrand = [] } = data
      this.hasData = !!topBrand.length
      const otherList = otherBrand.splice(0, 5)
      // 初始化数据
      this.option.series[0].data = []
      // 总数
      let sum = 0
      // 逐条添加数据
      topBrand.forEach((item, i) => {
        sum += item.value
        this.option.series[0].data.push({
          ...item
        })
      })
      let temp = ''
      otherList.forEach((item, i) => {
        // i !== 0 && (temp += '&nbsp;&nbsp;&nbsp;')
        temp += `${item.name}:&nbsp;${numFormat(item.value)}<br/>`
      })
      // tooltip
      this.option.tooltip.formatter = (params) => {
        if (params.name === '其他' && otherList.length) {
          return (
            temp
          )
        } else {
          return `${params.name}:&nbsp;${numFormat(params.data.value)}<br/>占比:&nbsp;${numFormat(params.data.value / sum * 100, 2) }%`
        }
      }
      // 样式
      this.option.series[0].itemStyle = {
        borderRadius: [5, 5, 5, 5],
        borderColor: '#fff',
        borderWidth: 2,
        color: (params) => {
          const colorList = [
            {
              c1: 'rgba(183, 105, 247, 1)',
              c2: 'rgba(138, 89, 251, 1)'
            },
            {
              c1: 'rgba(95, 233, 196, 1)',
              c2: 'rgba(62, 219, 223, 1)'
            },
            {
              c1: 'rgba(249, 169, 110, 1)',
              c2: 'rgba(248, 123, 104, 1)'
            },
            {
              c1: 'rgba(250, 84, 124, 1)',
              c2: 'rgba(247, 65, 108, 1)'
            },
            {
              c1: 'rgba(22, 178, 246, 1)',
              c2: 'rgba(31, 125, 238, 1)'
            },
            {
              c1: 'rgba(248, 202, 116, 1)',
              c2: 'rgba(252, 159, 74, 1)'
            },
            {
              c1: 'rgba(234, 116, 248, 1)',
              c2: 'rgba(173, 74, 252, 1)'
            },
            {
              c1: 'rgba(163, 248, 116, 1)',
              c2: 'rgba(199, 252, 74, 1)'
            },
            {
              c1: 'rgba(116, 248, 184, 1)',
              c2: 'rgba(74, 252, 168, 1)'
            },
            {
              c1: 'rgba(116, 199, 248, 1)',
              c2: 'rgba(74, 142, 252, 1)'
            }
          ]
          if (params.dataIndex > colorList.length - 1) {
            return 'none'
          }
          return new graphic.LinearGradient(0, 0, 0, 1, [
            {
              // 左、下、右、上
              offset: 0,
              color: colorList[params.dataIndex].c1
            },
            {
              offset: 1,
              color: colorList[params.dataIndex].c2
            }
          ])
        }
      }
      // 展位不显示半环
      this.option.series[0].data.push({
        // make an record to fill the bottom 50%
        value: sum,
        itemStyle: {
          // stop the chart from rendering this piece
          color: 'none',
          decal: {
            symbol: 'none'
          }
        },
        label: {
          show: false
        },
        tooltip: {
          show: false
        }
      })
      if (+this.modelType) {
        this.option.series[0].radius = ['50%', '70%']
      } else {
        this.option.series[0].radius = ['60%', '80%']
      }
      this.spinning = false
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';

.top-equipment-chart-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  padding-top: 5px;
  .control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    .app-select-box {
      line-height: 25px;
      vertical-align: middle;
      margin-top: 8px;
    }
  }

  .ant-spin-nested-loading {
    flex-grow: 1;
    height: 0;
    position: relative;

    .ant-spin-container {
      height: 100%;

      .chart {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .chart-box{
          position: relative;
          width: 100%;
          height: 100%;
        }
        .empty{
          width: 100%;
          height: 100%;
          background-image: url('/images/data-empty.png');
          background-size: 35%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
</style>
