export default {
  title: {
    show: false
  },
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    axisPointer: {
      type: 'shadow',
      shadowStyle: {
        color: 'rgba(0, 0, 0, 0.05)',
        width: '1'
      }
    }
  },
  legend: {
    top: '0%'
  },
  grid: {
    left: '3%',
    top: '15%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  yAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    show: true,
    minInterval: 1,
    axisTick: {
      show: false
    },
    splitLine: {
      show: true
    },
    axisLabel: {
      show: true,
      interval: 0
    },
    splitNumber: 0
  },
  xAxis: {
    type: 'category',
    data: ['Brazil', 'Indonesia', 'USA', 'India', 'China'],
    show: false,
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisLabel: {
      show: true,
      interval: 0,
      fontSize: '12px'
    },
    splitNumber: 0
  },
  series: [
    {
      name: '新增应用个数',
      xAxisIndex: 0,
      type: 'bar',
      data: [1, 2, 3, 4, 5],
      itemStyle: {
        color: 'rgba(183, 105, 247, 1)',
        borderRadius: 5
      },
      barWidth: '30%',
      label: {
        show: true,
        position: 'top',
        color: '#8d8d8d'
      }
    },
    {
      name: '升级应用次数',
      xAxisIndex: 0,
      type: 'bar',
      data: [2, 3, 3, 2, 3],
      itemStyle: {
        color: 'rgba(95, 233, 196, 1)',
        borderRadius: 5
      },
      barWidth: '30%',
      label: {
        show: true,
        position: 'top',
        color: '#8d8d8d'
      }
    }
  ]
}
