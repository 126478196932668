<template>
  <div class="card-item-container" :style="{'font-size': fontSize + 'px'}">
    <div class="title-box">
      <span class="title">{{ title }}</span>
    </div>
    <div class="value-box">
      <span
        class="value"
      >{{ numFormat(value, 3, '') }}{{ title === '流量填充率' ? '%' : title === '展示率' ? '%' : '' }}</span
      >
      <!-- <div class="circle" :style="{ borderColor: borderColor }"></div> -->
      <div class="chart-img">
        <img :src="`/images/home-board/chart${index + 1}.png`" alt="">
      </div>
    </div>
    <div class="propor-box" v-if="title !== 'DAU'">
      <div class="propor-title">环比</div>
      <span
        :class="{ propor: true, increase: propor > 0, decrease: propor < 0 }"
      >{{ propor > 0 ? '+' + propor : '' + propor }}%</span
      >
      <img v-if="propor > 0" style="width:1em;margin-left:0.4em" class="arrow-img" src="/images/home-board/ic_increase.png" alt="">
      <img v-else style="width:1em;margin-left:0.4em" class="arrow-img" src="/images/home-board/ic_increase12.png" alt="">
    </div>
  </div>
</template>

<script>
import { numFormat } from '@/utils/dealNumber'
import autoFontSize from '@/mixins/autoFontSize'
export default {
  name: 'RealCardItem',
  mixins: [autoFontSize],
  props: {
    title: {
      type: String,
      default: 'abc'
    },
    value: {
      type: [Number, String],
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    propor: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    numFormat
  }
}
</script>
<style lang="less" scoped>
.card-item-container {
  position: relative;
  min-height: 5.85em;
  background: #f9f9fc;
  border-radius: 0.35em;
  display: flex;
  flex-direction: column;
  padding-left: 1.45em;
  justify-content: flex-start;
  padding-top: 0.35em;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
  }
  cursor: pointer;
  div.title-box {
    height: 1.45em;
    line-height: 1.45em;
    margin-bottom: 0.22em;
    span.title {
      font-size: 1em;
      color: #999;
      line-height: 1em;
    }
  }
  div.value-box {
    height: 1.8em;
    line-height: 1.8em;
    padding-right: 1.45em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 0.145em;
    .chart-img{
      img{
        width: 2.15em;
      }
    }
    span.value {
      font-size: 1.45em;
      font-weight: 600;
      color: #000;
      line-height: 1.45em;
    }
    div.circle {
      width: 1.15em;
      height: 1.15em;
      border: 0.3em solid;
      border-radius: 50%;
    }
  }
  div.propor-box {
    font-size: 0.85;
    .propor-title {
      font-size: 0.95em;
      display: inline-block;
      margin-right: 0.215em;
      color: #b3b3b3;
    }
    .increase {
      color: #f02388;
    }
    .decrease {
      color: #27ca8a;
    }
  }
}
</style>
