<template>
  <a-modal
    v-model="changeModelVisible"
    title="修改模板"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="700px"
    dialogClass="set_model_modal"
    style="font-size: 14px"
  >
    <div class="model-container">
      <div class="model-list-wrapper">
        <template v-for="(item, index) in modelList">
          <div class="model-item" @click="modelId = item.name" :key="index" :class="{ active: modelId === item.name }">
            <!-- v-if="!hasUsedModles.includes(item.name)" -->
            <div class="model-item-box">
              <div class="top_title">
                <h3>{{ item.title }}</h3>
              </div>
              <div class="bottom_img">
                <img :src="item.img" :alt="item.title" :draggable="false" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { dashboardTemplateList } from '@/api/dashboard'
export default {
  data () {
    return {
      position: undefined,
      modelId: undefined,
      // hasUsedModles: [], // 已使用模块
      modelList: [
        // {
        //   title: 'top应用',
        //   name: 'barChartApp',
        //   type: 1,
        //   img: 'https://pic.netbian.com/uploads/allimg/230220/003215-16768243354083.jpg'
        // },
        // {
        //   title: '最新动态',
        //   name: 'operationLog',
        //   type: 0,
        //   img: 'https://pic.netbian.com/uploads/allimg/230220/003215-16768243354083.jpg'
        // },
        // {
        //   title: 'top广告平台',
        //   name: 'pieChartPlat',
        //   type: 0,
        //   img: 'https://pic.netbian.com/uploads/allimg/230220/003215-16768243354083.jpg'
        // },
        // {
        //   title: 'top公司',
        //   name: 'pieChartConpany',
        //   type: 0,
        //   img: 'https://pic.netbian.com/uploads/allimg/230220/003215-16768243354083.jpg'
        // },
        // {
        //   title: 'top广告样式',
        //   name: 'pieChartPosition',
        //   type: 0,
        //   img: 'https://pic.netbian.com/uploads/allimg/230220/003215-16768243354083.jpg'
        // }
      ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modelInfo: {
      type: Object,
      default: () => ({})
    },
    models: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.position = this.modelInfo.position
          this.modelId = this.modelInfo.id
          this.getModelList(this.modelInfo.type)
          // this.hasUsedModles = Object.values(this.models).filter((item) => item !== this.modelInfo.id)
        }
      }
    }
  },
  computed: {
    changeModelVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 获取模块列表
    async getModelList (type) {
      const { data } = await dashboardTemplateList({ type })
      this.modelList = data || []
    },
    handleCancel () {
      this.changeModelVisible = false
    },
    handleSubmit () {
      this.$emit('changeModel', {
        modelId: this.modelId,
        position: this.position
      })
      this.changeModelVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.model-container {
  height: 60vh;
  box-sizing: border-box;
  overflow-y: auto;
  .model-list-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .model-item {
      user-select: none;
      box-sizing: border-box;
      width: 48%;
      border-radius: 10px;
      height: 300px;
      padding: 0 15px 15px 15px;
      background: #f6f7f9;
      margin: 5px;
      cursor: pointer;
      border-width: 2px;
      border-style: solid;
      border-color: #fff;
      position: relative;
      transition: all 0.3s;
      &.active {
        border-color: @primary-color;
      }
      .model-item-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .top_title {
          height: 50px;
          font-size: 14px;
          font-weight: bold;
          line-height: 50px;
        }
        .bottom_img {
          box-sizing: border-box;
          flex-grow: 1;
          height: 0;
          position: relative;
          padding: 0 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.set_model_modal .ant-modal-body {
  background-color: #fff;
}
</style>
