export default {
  tooltip: {
    trigger: 'item',
    transitionDuration: 0,
    appendToBody: true
  },
  legend: {
    top: '10%',
    itemGap: 12,
    itemWidth: 14,
    itemHeight: 10,
    selectedMode: false
  },
  series: [
    {
      radius: ['40%', '70%'],
      center: ['50%', '60%'],
      type: 'pie',
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
        normal: {
          show: true,
          position: 'center',
          color: '#000',
          formatter: `广告样式`,
          fontSize: '16',
          fontWeight: 500,
          lineHeight: 30
        },
        emphasis: {
          // 中间文字显示
          show: true
        }
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          color: '#000',
          fontWeight: 'bold',
          formatter: `广告样式`
        }
      },
      labelLine: {
        show: false
      },
      data: [
        {
          value: 300,
          name: ''
        }
      ]
    }
  ]
}
