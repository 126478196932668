<template>
  <div class="company-chart-container">
    <div class="company-chart-top">
      <a-dropdown overlayClassName="company-chart-type-dropdown">
        <span class="current-type"> {{ currentName }} <a-icon type="down" /> </span>
        <a-menu :selectedKeys="[currentType]" slot="overlay">
          <a-menu-item class="menu-item" v-for="item in typeList" :key="item.id" @click="changeType(item)">
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <div class="company-chart-top-right">
        <a-range-picker
          :disabled="+isMock === 1"
          v-show="currentType === 'revenue'"
          style="width: 120px"
          format="MM-DD"
          :placeholder="['Start', 'End']"
          v-model="dates"
          :allowClear="false"
          :disabledDate="disabledDate"
          @change="changeDate"
          size="small"
          ref="datepicker"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :align="{
            offset: ['-50px', '0px']
          }"
        />
        <a-month-picker
          :disabled="+isMock === 1"
          :allowClear="false"
          :disabledDate="disabledMonth"
          style="width: 120px"
          v-show="currentType === 'appCount'"
          v-model="month"
          ref="monthpicker"
          class="month-picker"
          @change="changeMonth"
          size="small"
          placeholder="Select Month"
        />
      </div>
    </div>
    <div class="company-chart-content">
      <pieCompanyRevenue
        v-if="currentType === 'revenue'"
        v-bind="{
          ...$attrs,
          query: { dateStart, dateEnd, type: 'revenue' }
        }"
      />
      <pieCompanyCount
        v-if="currentType === 'appCount'"
        v-bind="{
          ...$attrs,
          query: { monthStr, type: 'appCount' }
        }"
      />
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import pieCompanyRevenue from './pieCompanyRevenue.vue'
import pieCompanyCount from './pieCompanyCount.vue'
import { mapState } from 'vuex'
export default {
  mixins: [mixDate],
  components: { pieCompanyRevenue, pieCompanyCount },
  data () {
    return {
      currentType: 'revenue',
      currentName: 'Top公司收入',
      typeList: [
        { name: 'Top公司收入', id: 'revenue' },
        { name: 'Top公司接入', id: 'appCount' }
      ],
      dates: [],
      dateStart: '',
      dateEnd: '',
      month: undefined,
      monthStr: ''
    }
  },
  props: {
    pos: {
      default: '',
      type: String
    }
  },
  created () {
    const { modelDimensionMap = {} } = this.sysUserDashboardSetting
    this.currentType = modelDimensionMap[this.pos].pieChartConpany || 'revenue'
    this.currentName = this.typeList.find((item) => item.id === this.currentType).name
    const { end, start, endDate, startDate } = this.initDate(1, 1)
    this.dates = [start, end]
    this.dateStart = startDate
    this.dateEnd = endDate
    this.monthStr = this.moment().format('YYYY-MM')
    this.month = this.moment(this.monthStr, 'YYYY-MM')
  },
  computed: {
    ...mapState({
      sysUserDashboardSetting: (state) => state.user.sysUserDashboardSetting,
      isMock: (state) => state.user.isMock
    })
  },
  mounted () {
  },
  methods: {
    changeMonth (month, monthStr) {
      this.monthStr = monthStr
    },
    changeDate (date, dateStr) {
      this.dateStart = date[0].format('YYYY-MM-DD')
      this.dateEnd = date[1].format('YYYY-MM-DD')
    },
    changeType (item) {
      this.currentType = item.id
      this.currentName = item.name
      const sysUserDashboardSetting = {
        ...this.sysUserDashboardSetting,
        modelDimensionMap: {
          ...this.sysUserDashboardSetting.modelDimensionMap,
          [this.pos]: {
            ...this.sysUserDashboardSetting.modelDimensionMap[this.pos],
            pieChartConpany: this.currentType
          }
        }
      }
      this.$store.dispatch('changeSysUserDashboardSetting', sysUserDashboardSetting)
    },
    // 不可选时间
    disabledDate (current) {
      return (current && current < this.initDate(31, 0).start) || current >= this.initDate(1, 0).start
    },
    // 不可选时间
    disabledMonth (current) {
      return current > this.initDate(0, 0).end
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.company-chart-container .ant-calendar-picker.ant-calendar-picker-small {
  .ant-calendar-picker-input.ant-input:focus {
    box-shadow: none !important;
  }
}
.company-chart-container {
  height: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  .company-chart-top {
    display: flex;
    height: 32px;
    justify-content: space-between;
    padding-right: 10px;
    .current-type {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      cursor: pointer;
      &::after {
        content: '';
        width: 3px;
        height: 9px;
        background: red;
        position: absolute;
        top: 6px;
        left: -6px;
      }
    }
    .icon-down {
      margin-left: 5px;
    }
    .menu-item {
      width: 120px;
    }
    .company-chart-top-right {
      position: relative;
      // width: 30px;
      // .month-picker{
      //   height: 100%;
      //   position: absolute!important;
      //   top: 0;
      //   left: 0;
      //   height: 30px;
      // }
      .el-icon-date {
        font-size: 18px;
        line-height: 25px;
        margin-right: 5px;
        color: #999;
        vertical-align: middle;
      }
    }
  }
  .company-chart-content {
    height: 0;
    flex-grow: 1;
    position: relative;
  }
}
</style>
<style lang="less">
.company-chart-type-dropdown {
  width: auto;
}
</style>
