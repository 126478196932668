export const countMockData = {
  code: 200,
  msg: 'success',
  data: {
    addApp: 0,
    upgradeApp: 0,
    sum: 0,
    list: [
      {
        company: '演示数据专用公司',
        addApp: 0,
        upgradeApp: 0,
        sum: 0
      }
    ]
  }
}

export const revenueMockData = {
  code: 200,
  msg: 'success',
  data: {
    unitRevenueSum: 103664.65,
    list: [
      {
        company: '演示数据专用公司',
        unitRevenue: 103664.65
      }
    ]
  }
}
