<template>
  <div class="pie-plat-chart-container">
    <div class="control">
      <HomeTitle :title="'Top广告平台'" :borderColor="'#008c8c'" />
      <div class="date-picker-wrapper">
        <a-range-picker
          :disabled="+isMock === 1"
          style="width: 120px"
          format="MM-DD"
          :placeholder="['Start', 'End']"
          v-model="date"
          :allowClear="false"
          :disabledDate="disabledDate"
          @change="changeDate"
          size="small"
          ref="datepicker"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :align="{
            offset: ['-50px', '0px']
          }"
        />
      </div>
    </div>
    <a-spin :spinning="spinning">
      <div class="chart" ref="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../../components/homeTitle.vue'
import { pieChartData } from '@/api/dashboard'
import option from './options'
import { numFormat } from '@/utils/dealNumber'
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
import { mockData } from './mockData'
import * as echarts from 'echarts'
export default {
  components: { HomeTitle },
  data () {
    return {
      spinning: false,
      query: {
        code: 'unitRevenue',
        dimension: '4',
        endDate: '2022-08-22',
        startDate: '2022-08-16'
      },
      date: [],
      option: {},
      myEcharts: null
    }
  },
  props: {
    modelType: {
      default: 0,
      type: Number
    }
  },
  watch: {
    isMock: {
      handler () {
        this.getChartsData()
      }
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  mixins: [mixDate],
  created () {
    // parse返回Object类型, 对应给定JSON文本的对象/值。
    this.option = JSON.parse(JSON.stringify(option))
    this.setDate(this.initDate(1, 1))
    // 当占位在大模块时 即modelType=1
    // if (this.modelType === 1) {
    //   this.option.legend.left = 'center'
    //   this.option.legend.top = '75%'
    //   this.option.series[0].radius = ['45%', '65%']
    //   this.option.series[0].center = ['50%', '40%']
    //   // this.option.length.orient = 'horizontal'
    // }
  },
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    this.initChart()
    window.addEventListener('resize', this.getSize)
    this.getChartsData()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    changeDate (date) {
      this.query.startDate = date[0].format('YYYY-MM-DD')
      this.query.endDate = date[1].format('YYYY-MM-DD')
      this.getChartsData()
    },
    // 不可选时间
    disabledDate (current) {
      return (current && current < this.initDate(31, 0).start) || current >= this.initDate(1, 0).start
    },
    setDate (obj) {
      const { start, end, endDate, startDate } = obj
      this.query.startDate = startDate
      this.query.endDate = endDate
      this.date = [start, end]
    },
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    },
    async getChartsData () {
      this.spinning = true
      let resp
      if (+this.isMock === 0) {
        resp = await pieChartData(this.query)
      } else {
        resp = mockData
      }
      const sum = resp.data.unitRevenueSum
      this.option.legend.formatter = (name, a) => {
        const datas = resp.data.list
        const unitRevenue = datas.filter((item) => item.platName === name)[0].unitRevenue
        const propor = unitRevenue / sum

        var arr = ['{a|' + name + '}', '{b|' + (sum === 0 ? 0 : (propor * 100).toFixed(2)) + '%}']
        return arr.join('  ')
      }
      this.option.legend.textStyle = {
        // 添加
        // padding: [8, 0, 0, 0],
        rich: {
          // y颜色种类介绍
          a: {
            fontSize: 12,
            width: 60,
            color: '#888'
          },
          b: {
            fontSize: 12,
            width: 70
          }
        }
      }
      // 格式化
      this.option.tooltip.formatter = (params) => {
        return (
          params.marker +
          '<span>&nbsp;</ span>平台：' +
          (params.name || '其他') +
          '<div>&nbsp;&nbsp;&nbsp;&nbsp;</ div>收益：' +
          numFormat(params.data.value, 3, '') +
          '<div>&nbsp;&nbsp;&nbsp;&nbsp;</ div>占比：' +
          (!params.data.value ? 0 : sum === 0 ? 0 : ((params.data.value / sum) * 100).toFixed(2)) +
          '%'
        )
      }
      this.option.tooltip.position = (point, params, dom, rect, size) => {
        var x = 0 // x坐标位置
        var y = 0 // y坐标位置
        // 当前鼠标位置
        var pointX = point[0]
        var pointY = point[1]
        // 提示框大小
        var boxWidth = size.contentSize[0]
        var boxHeight = size.contentSize[1]

        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = pointX + 10
        } else {
          // 左边放的下
          x = pointX - boxWidth - 10
        }

        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5
        } else {
          // 上边放得下
          y = pointY - boxHeight
        }
        return [x, y]
      }
      this.option.series[0].itemStyle = {
        borderRadius: [5, 5, 5, 5],
        borderColor: '#fff',
        borderWidth: 2,
        color: (params) => {
          const colorList = [
            {
              c1: 'rgba(183, 105, 247, 1)',
              c2: 'rgba(138, 89, 251, 1)'
            },
            {
              c1: 'rgba(95, 233, 196, 1)',
              c2: 'rgba(62, 219, 223, 1)'
            },
            {
              c1: 'rgba(249, 169, 110, 1)',
              c2: 'rgba(248, 123, 104, 1)'
            },
            {
              c1: 'rgba(250, 84, 124, 1)',
              c2: 'rgba(247, 65, 108, 1)'
            },
            {
              c1: 'rgba(22, 178, 246, 1)',
              c2: 'rgba(31, 125, 238, 1)'
            },
            {
              c1: 'rgba(248, 202, 116, 1)',
              c2: 'rgba(252, 159, 74, 1)'
            }
          ]
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              // 左、下、右、上
              offset: 0,
              color: colorList[params.dataIndex].c1
            },
            {
              offset: 1,
              color: colorList[params.dataIndex].c2
            }
          ])
        }
      }
      this.option.series[0].data = resp.data.list
        ? resp.data.list.map((item, i) => {
            return {
              value: item.unitRevenue,
              name: item.platName || '其他'
            }
          })
        : [
            {
              value: 0,
              name: ''
            }
          ]

      this.spinning = false
      this.initChart()
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';

.pie-plat-chart-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  padding-top: 5px;
  .control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    .date-picker-wrapper {
      line-height: 25px;
      vertical-align: middle;
      margin-top: 8px;
      .el-icon-date {
        font-size: 18px;
        line-height: 25px;
        margin-right: 5px;
        color: #999;
        vertical-align: middle;
      }
    }
  }

  .ant-spin-nested-loading {
    flex-grow: 1;
    height: 0;
    position: relative;

    .ant-spin-container {
      height: 100%;

      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.pie-plat-chart-container {
  .aRadioBtn();
}
</style>
