const middleWidth = undefined
const leftWidth = undefined
export const appName = [
  {
    dataIndex: 'appName',
    title: '应用',
    scopedSlots: { customRender: 'appName' },
    align: 'left',
    width: leftWidth

  }
]
export const placeName = [
  {
    dataIndex: 'placeName',
    title: '广告位',
    scopedSlots: { customRender: 'placeName' },
    align: 'left',
    width: leftWidth
  }
]
export const ratio = [
  {
    dataIndex: 'ratio',
    title: '环比',
    scopedSlots: { customRender: 'ratio' },
    align: 'left',
    width: undefined
  }
]

export const unitRevenue = [
  {
    dataIndex: 'unitRevenue',
    scopedSlots: { customRender: 'unitRevenue' },
    title: '收益',
    align: 'left',
    width: middleWidth
  }
]

export const ecpm = [
  {
    dataIndex: 'ecpm',
    scopedSlots: { customRender: 'middle' },
    title: 'ECPM',
    align: 'left',
    width: middleWidth
  }
]

export const arpdau = [
  {
    dataIndex: 'arpDau',
    scopedSlots: { customRender: 'middle' },
    title: 'ARPDAU',
    align: 'left',
    width: middleWidth
  }
]

export const arpdeu = [
  {
    dataIndex: 'arpDeu',
    scopedSlots: { customRender: 'middle' },
    title: 'ARPDEU',
    align: 'left',
    width: middleWidth
  }
]

export const dau = [
  {
    dataIndex: 'dau',
    scopedSlots: { customRender: 'middle' },
    title: 'DAU',
    align: 'left',
    width: middleWidth
  }
]

export const deu = [
  {
    dataIndex: 'deu',
    scopedSlots: { customRender: 'middle' },
    title: 'DEU',
    align: 'left',
    width: middleWidth
  }
]
