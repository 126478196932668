export const mockData = {
  code: 200,
  msg: 'success',
  data: {
    incomeSum: 0,
    unitRevenueSum: 87352.79,
    dauSum: 0,
    list: [
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '优量汇',
        platId: 2,
        sourceName: '',
        positionName: '',
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 42323.4,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '快手',
        platId: 3,
        sourceName: '',
        positionName: '',
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 25420.28,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: 'Sigmob',
        platId: 7,
        sourceName: '',
        positionName: '',
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 17287.44,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '百度联盟',
        platId: 6,
        sourceName: '',
        positionName: '',
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 1307.43,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '穿山甲',
        platId: 8,
        sourceName: '',
        positionName: '',
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 948.97,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        appName: '',
        placeName: '',
        groupName: '',
        platName: '',
        sourceName: '',
        positionName: '',
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        company: '其他',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 65.27,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      }
    ]
  }
}
