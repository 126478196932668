<template>
  <div class="real-time-data-container" :style="{'font-size': fontSize + 'px'}">
    <a-row class="row" align="middle" :gutter="10" type="flex">
      <a-col v-for="(item, i) in cardList" :key="i" class="col-item">
        <CardItem
          :title="item.label"
          :value="dataList && dataList[item.key] ? dataList[item.key] : ''"
          :index="i"
          :propor="dataList && dataList[item.propor] ? dataList[item.propor] : 0" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getRealTimeData } from '@/api/dashboard'
import HomeTitle from '../components/homeTitle'
import CardItem from './realCardItem.vue'
import { mapState } from 'vuex'
import { mockData } from './mockData'
import autoFontSize from '@/mixins/autoFontSize'
export default {
  components: { CardItem, HomeTitle },
  mixins: [autoFontSize],
  name: 'RealTimeCard',
  data () {
    return {
      dataList: {},
      cardList: [
        {
          propor: 'incomeRatio',
          key: 'income',
          label: '今日预估收益',
          color: '#7ACDED'
        },
        {
          propor: 'ecpmRatio',
          key: 'ecpm',
          label: 'eCPM',
          color: '#EF6666'
        },
        {
          propor: 'requestRatio',
          key: 'request',
          label: '流量请求',
          color: '#FAC858'
        },
        {
          propor: 'responsedRatio',
          key: 'responseFilledRatio',
          label: '流量填充率',
          color: '#91CC75'
        },
        {
          propor: 'impressRatio',
          key: 'impress',
          label: '展示',
          color: '#5470C6'
        },
        {
          propor: 'impressFilledRatio2',
          key: 'impressFilledRatio',
          label: '展示率',
          color: '#008c8c'
        },
        {
          propor: '',
          key: 'dau',
          label: 'DAU',
          color: '#ff4400'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  watch: {
    isMock: {
      handler (val) {
        this.getRealTimeData()
      }
    }
  },
  mounted () {
    this.getRealTimeData()
  },
  methods: {
    async getRealTimeData () {
      let resp
      if (+this.isMock === 1) {
        resp = mockData
      } else {
        resp = await getRealTimeData()
      }
      this.dataList = resp.data
    }
  }
}
</script>

<style lang="less" scoped>
.real-time-data-container {
  background: #fff;
  height: 100%;
  border-radius: 0.35em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.21em 1.42em 0.35em 1.42em;

  .home_title {
    margin-left: -1.42em;
  }

  .row {
    flex-flow: 1;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;

    .col-item {
      position: relative;
      padding: 0.35em 0;
      width: 14.2%;
      min-width: 11.42em;
    }
  }
}</style>
