<template>
  <div class="company-count-container">
    <a-spin :spinning="spinning">
      <div class="chart" ref="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import { dashboardCompany } from '@/api/dashboard'
import option from './countoptions'
import { mapState } from 'vuex'
import { countMockData } from './mockData'
import * as echarts from 'echarts'
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    modelType: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      option: {},
      myEcharts: undefined,
      spinning: false
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  created () {
    // parse返回Object类型, 对应给定JSON文本的对象/值。
    this.option = JSON.parse(JSON.stringify(option))
    if (this.modelType === 1) {
    }
  },
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  watch: {
    query: {
      handler (val, oldVal) {
        if (oldVal && val.monthStr === oldVal.monthStr) return
        this.$nextTick(() => {
          this.getChartData()
        })
      },
      immediate: true,
      deep: true
    },
    isMock: {
      handler () {
        this.getChartData()
      }
    }
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    },
    async getChartData () {
      this.spinning = true
      let resp
      if (+this.isMock === 0) {
        resp = await dashboardCompany({
          ...this.query,
          month: this.query.monthStr
        })
      } else {
        resp = countMockData
      }
      const list = resp.data.list
      const addAppList = list.map((item) => item.addApp)
      const upgradeAppList = list.map((item) => item.upgradeApp)
      const companyList = list.map((item) => item.company)
      this.option.xAxis.data = companyList || []
      this.option.series[0].data = addAppList || []
      this.option.series[1].data = upgradeAppList || []
      this.spinning = false
      this.initChart()
    }
  }
}
</script>

<style lang="less" scoped>
.company-count-container {
  height: 100%;
  position: relative;
}
</style>
<style lang="less">
.company-count-container {
  .ant-spin-nested-loading {
    position: relative;
    height: 100%;
    .ant-spin-container {
      width: 100%;
      height: 100%;
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
