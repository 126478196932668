<template>
  <div class="bar-chart-app-container">
    <div class="control">
      <div class="home_title">
        <a-dropdown overlayClassName="app-chart-type-dropdown">
          <span class="title">
            {{ ![3, 4].includes(selectedKeys[0]) ? 'Top应用' : '' }}{{ title }} <a-icon type="down" />
          </span>
          <a-menu slot="overlay" :selectedKeys="selectedKeys" class="menuBox">
            <a-menu-item class="menu-item" v-for="item in optionArr" :key="item.id" @click="clickMenu(item)">
              {{ item.name }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="date_radio">
        <a-range-picker
          style="width: 120px"
          format="MM-DD"
          :disabled="+isMock === 1"
          :placeholder="['Start', 'End']"
          @change="onChangeDate"
          @openChange="openChange"
          @calendarChange="calendarChange"
          v-model="rangeTime"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :allowClear="false"
          :disabledDate="disabledDate"
          size="small"
          ref="datepicker"
        />
      </div>
    </div>
    <a-spin :spinning="spinning" class="spinBox">
      <div class="tableBox">
        <a-table
          :loading="loading"
          :pagination="false"
          :columns="columns"
          :data-source="dataList"
          :rowKey="(record, index) => index"
        >
          <template v-if="query.type === 2" slot="placeName" slot-scope="text, record">
            <div class="placeName">
              <IosSvg
                v-if="record.os === 0"
                style="min-width: 14px; max-width: 14px; height: 14px; margin-right: 3px"
              />
              <AndroidSvg
                class="androidSvg"
                v-if="record.os === 1"
                style="min-width: 14px; max-width: 14px; height: 14px; margin-right: 3px"
              />
              <span :title="record.placeName">{{ record.placeName }}</span>
            </div>
          </template>
          <template v-if="query.type !== 2" slot="appName" slot-scope="text, record">
            <a-popover
              trigger="hover"
              placement="topRight"
              v-if="record.isBind === 1 && +query.type === 1"
              overlayClassName="poperLayTable"
            >
              <template #content>
                <div>
                  <span>{{ record?.android?.appName }}</span>
                  <div class="popover-item">
                    <span class="firstSpan">{{ record?.android?.os === 0 ? 'IOS' : '安卓' }}</span>
                    <span>{{ numFormat(record?.android?.unitRevenue, 3, '') }}</span>
                  </div>
                </div>
                <div class="popover-item">
                  <span class="firstSpan">{{ record?.ios?.os === 0 ? 'IOS' : '安卓' }}</span>
                  <span>{{ numFormat(record?.ios?.unitRevenue, 3, '') }} </span>
                </div>
              </template>
              <div class="appName">
                <img :src="iosAnd" alt="" />
                <span>{{ record.appName }}</span>
              </div>
            </a-popover>
            <a-popover
              trigger="hover"
              placement="topRight"
              v-else-if="record.isBind === 1 && +query.type === 5"
              overlayClassName="poperLayTable"
            >
              <template #content>
                <div>
                  <span>{{ record?.android?.appName }}</span>
                  <div class="popover-item">
                    <span class="firstSpan">{{ record?.android?.os === 0 ? 'IOS' : '安卓' }}</span>
                    <span>{{ numFormat(record?.android?.dau, 3, '') }}</span>
                  </div>
                </div>
                <div class="popover-item">
                  <span class="firstSpan">{{ record?.ios?.os === 0 ? 'IOS' : '安卓' }}</span>
                  <span>{{ numFormat(record?.ios?.dau, 3, '') }} </span>
                </div>
              </template>
              <div class="appName">
                <img :src="iosAnd" alt="" />
                <span>{{ record.appName }}</span>
              </div>
            </a-popover>
            <div :title="record.appName" class="appName app-name-box" v-else>
              <IosSvg
                v-if="record.os === 0"
                style="min-width: 14px; max-width: 14px; height: 14px; margin-right: 3px"
              />
              <AndroidSvg
                class="androidSvg"
                v-if="record.os === 1"
                style="min-width: 14px; max-width: 14px; height: 14px; margin-right: 3px"
              />
              <span>{{ record.appName }}</span>
            </div>
          </template>
          <template slot="unitRevenue" slot-scope="text, record">
            <a-popover placement="right" trigger="hover" v-if="record.isBind === 1" overlayClassName="poperLayTable">
              <template #content>
                <div>
                  <span>{{ record?.android?.appName }}</span>
                  <div class="popover-item">
                    <span class="firstSpan">{{ record?.android?.os === 0 ? 'IOS' : '安卓' }}</span>
                    <span>{{ numFormat(record?.android?.unitRevenue, 3, '') }}</span>
                  </div>
                </div>
                <div class="popover-item">
                  <span class="firstSpan">{{ record?.ios?.os === 0 ? 'IOS' : '安卓' }}</span>
                  <span>{{ numFormat(record?.ios?.unitRevenue, 3, '') }} </span>
                </div>
              </template>
              {{ record.unitRevenue }}
            </a-popover>
            <span v-if="record.isBind === 0">{{ record.unitRevenue }}</span>
          </template>
          <template slot="ratio" slot-scope="text, record">
            <span
              style="max-width: 120px; display: inline-block"
              :class="{ increase: record.ratio > 0, decrease: record.ratio < 0 }"
            >{{ record.ratio > 0 ? '+' + record.ratio : '' + record.ratio }}%</span
            >
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>
<script>
import mixDate from '@/mixins/initDate'
import { appName, unitRevenue, ecpm, arpdau, arpdeu, dau, deu, ratio, placeName } from './list'
import { barChartDataforApp } from '@/api/dashboard'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
import mockData from './mockData'

export default {
  name: 'BarChartApp',
  components: { IosSvg, AndroidSvg },
  mixins: [mixDate],
  data () {
    return {
      appName,
      unitRevenue,
      ecpm,
      arpdau,
      arpdeu,
      dau,
      deu,
      ratio,
      placeName,
      spinning: false,
      query: {
        startDate: '',
        endDate: '',
        type: 1
      },
      optionArr: [
        { name: 'ECPM', id: 2 },
        { name: '收入', id: 1 },
        { name: 'ARPUDAU', id: 3 },
        { name: 'ARPUDEU', id: 4 },
        { name: 'DAU', id: 5 },
        { name: 'DEU', id: 6 }
      ],
      title: 'ECPM',
      selectedKeys: [2],
      loading: false,
      dataList: [],
      columns: [],
      rangeTime: [],
      selectCurrentDate: '',
      iosAnd: require('@/assets/images/iosAnd.png')
    }
  },
  props: {
    pos: {
      type: String,
      default: ''
    }
  },
  created () {
    const { modelDimensionMap = {} } = this.sysUserDashboardSetting
    this.selectedKeys = [+modelDimensionMap[this.pos].barChartApp || 2]
    this.title = this.optionArr.find((item) => +item.id === +this.selectedKeys[0]).name
    const { start, end, endDate, startDate } = this.initDate(1, 1)
    this.rangeTime = [start, end]
    this.query.startDate = startDate
    this.query.endDate = endDate
    const list = {
      1: this.unitRevenue,
      2: this.ecpm,
      3: this.arpdau,
      4: this.arpdeu,
      5: this.dau,
      6: this.deu
    }
    this.query.type = this.selectedKeys[0]
    const column = this.query.type === 2 ? this.placeName : this.appName
    this.columns = [...column, ...list[this.query.type], ...this.ratio]
  },
  async mounted () {
    await this.getDatas()
    window.addEventListener('resize', this.changeTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.changeTrHeight)
  },
  computed: {
    ...mapState({
      sysUserDashboardSetting: (state) => state.user.sysUserDashboardSetting,
      isMock: (state) => state.user.isMock
    })
  },
  watch: {
    isMock: {
      handler () {
        this.getDatas()
      }
    }
  },
  methods: {
    changeTrHeight () {
      this.$nextTick(() => {
        const clientWidth = document.documentElement.clientWidth < 1280 ? 1280 : document.documentElement.clientWidth
        const height = (48 * clientWidth) / 1400
        const trList = document.getElementsByClassName('bar-chart-app-container')[0]?.getElementsByTagName('tr')
        for (let i = 0; i < trList?.length; i++) {
          trList[i].style.height = `${height}px`
        }
      })
    },
    numFormat,
    changePicker (a, b) {
      this.rangeTime = a.length ? b : []
    },
    // 处理打开日期面板事件
    openChange (val) {
      if (val) {
        this.selectCurrentDate = this.rangeTime.length ? this.rangeTime[0] : null
      }
    },
    // 不可选时间
    disabledDate (current) {
      return (current && current < this.initDate(31, 0).start) || current >= this.initDate(1, 0).start
    },
    // 点击面板时间事件
    calendarChange (date, dateString) {
      if (date.length <= 1) {
        this.selectCurrentDate = date[0]
      } else {
        this.selectCurrentDate = null
      }
    },
    // 点击展开
    clickOpen () {
      this.ifOpen = !this.ifOpen
    },
    // 点击选择
    clickMenu (data) {
      this.title = data.name
      this.selectedKeys = [data.id]
      this.query.type = data.id
      this.ifOpen = false
      const list = {
        1: this.unitRevenue,
        2: this.ecpm,
        3: this.arpdau,
        4: this.arpdeu,
        5: this.dau,
        6: this.deu
      }
      const column = data.id === 2 ? this.placeName : this.appName
      this.columns = [...column, ...list[data.id], ...this.ratio]
      this.getDatas()
      const sysUserDashboardSetting = {
        ...this.sysUserDashboardSetting,
        modelDimensionMap: {
          ...this.sysUserDashboardSetting.modelDimensionMap,
          [this.pos]: {
            ...this.sysUserDashboardSetting.modelDimensionMap[this.pos],
            barChartApp: this.selectedKeys[0]
          }
        }
      }
      this.$store.dispatch('changeSysUserDashboardSetting', sysUserDashboardSetting)
    },
    onChangeDate (date, dateString) {
      this.rangeTime = date
      this.query.startDate = date[0].format('YYYY-MM-DD')
      this.query.endDate = date[1].format('YYYY-MM-DD')
      this.getDatas()
    },
    async getDatas () {
      this.spinning = true
      let resp
      if (+this.isMock === 0) {
        resp = await barChartDataforApp(this.query)
      } else {
        const mockDataTemp = JSON.parse(JSON.stringify(mockData))
        if (+this.query.type === 1) {
          resp = mockDataTemp.incomeMockData
        } else if (+this.query.type === 2) {
          resp = mockDataTemp.ecpmMockData
        } else if (+this.query.type === 3) {
          resp = mockDataTemp.arpudauMockData
        } else if (+this.query.type === 4) {
          resp = mockDataTemp.arpudeuMockData
        } else if (+this.query.type === 5) {
          resp = mockDataTemp.dauMockData
        } else if (+this.query.type === 6) {
          resp = mockDataTemp.deuMockData
        }
      }
      this.dataList = resp.data || []
      this.dataList.forEach((item) => {
        item.unitRevenue = numFormat(+item.unitRevenue, 3, '')
        item.ecpm = numFormat(+item.ecpm, 3, '')
        item.dau = numFormat(+item.dau, 3, '')
        item.deu = numFormat(+item.deu, 3, '')
      })
      this.spinning = false
      this.changeTrHeight()
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.bar-chart-app-container {
  background: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 10px 3px 3px 3px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .app-name-box {
    min-width: 80px;
    max-width: 100px;
  }
  .control {
    padding: 5px;
    padding-right: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .home_title {
      position: relative;
      padding: 0px 0px 4px 4px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      .title {
        cursor: pointer;
        border-left: 3px solid;
        padding-left: 5px;
        height: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 10px;
        vertical-align: middle;
        transform: scale(0.9);
        display: flex;
        align-items: center;
      }
    }
    .reportdatas-container .ant-calendar-picker-input.ant-input {
      padding: 4px 3px;
      .ant-calendar-range-picker-input {
        font-size: 12px;
        width: 40%;
      }
    }
    .date_radio {
      margin-right: 15px;
    }
  }
  .spinBox {
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &:hover::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &:hover::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.1);
    }
    .tableBox {
      overflow-y: auto;
      height: 100%;
      padding: 10px 15px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .increase {
        color: #f02388;
      }
      .decrease {
        color: #27ca8a;
      }
    }
    .placeName {
      display: flex;
      align-items: center;
      .androidSvg {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
      span {
        max-width: 100px;
        min-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .appName {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 25px;
        height: 14px;
        margin-right: 3px;
      }
      .androidSvg {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
      span {
        max-width: 100px;
        min-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .middle {
      max-width: 150px;
    }
  }
}
::v-deep {
  .ant-dropdown-menu {
    padding: 0 0;
    border-radius: 8px;
  }
  .ant-dropdown-menu-item {
    text-align: center;
    padding: 5px 30px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(153, 153, 153, 0.1);
  }
  .ant-table-thead > tr > th {
    background: #fff;
    color: #999999;
  }
  .ant-table-placeholder {
    border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    padding: 3%;
  }
  .ant-table-thead > tr > th {
    padding: 10px;
  }
  .ant-table-tbody > tr:last-child td {
    border-bottom: none;
  }
}
::v-deep.control-container .ant-calendar-picker.ant-calendar-picker-small {
  .ant-calendar-picker-input.ant-input:focus {
    box-shadow: none !important;
  }
}
</style>
<style lang="less">
.poperLayTable {
  .ant-popover-inner {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    .ant-popover-inner-content {
      color: #fff;
      padding: 6px 8px;
      font-size: 14px;
      .popover-item {
        display: flex;
        justify-content: space-between;
        .firstSpan {
          margin-right: 60px;
        }
      }
    }
  }
  .ant-popover-arrow {
    display: none;
  }
}
</style>
