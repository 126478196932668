export default {
  // 收入
  incomeMockData: {
    code: 200,
    msg: 'success',
    data: [{
        appId: '',
        appName: '抖音',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 72280,
        historyUnitRevenue: 57684.22,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 25.3,
        isBind: 0
      },
      {
        appId: '',
        appName: '快手',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 9565.96,
        historyUnitRevenue: 7149.69,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 33.8,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 7130.43,
        historyUnitRevenue: 3900.56,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 82.81,
        isBind: 0
      },
      {
        appId: '',
        appName: '抖音',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 6600.19,
        historyUnitRevenue: 3456.72,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 90.94,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 6302.94,
        historyUnitRevenue: 6041.1,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 4.33,
        isBind: 0
      }
    ]
  },
  // ecpm
  ecpmMockData: {
    code: 200,
    msg: 'success',
    data: [{
        appId: '',
        appName: '抖音',
        os: 0,
        placeName: '抖音-ios-激励视频',
        placeId: 'e9703aab509548b9',
        impress: 78188,
        historyImpress: 78248,
        unitRevenue: 6600.19,
        historyUnitRevenue: 3456.72,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 84.41,
        historyEcpm: 44.18,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 91.06
      },
      {
        appId: '',
        appName: 'UC浏览器',
        os: 1,
        placeName: 'UC浏览器-安卓-插屏',
        placeId: '2635b76a03166ae2',
        impress: 166,
        historyImpress: 139,
        unitRevenue: 10.44,
        historyUnitRevenue: 5.02,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 62.89,
        historyEcpm: 36.12,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 74.11
      },
      {
        appId: '',
        appName: '京东',
        os: 0,
        placeName: '京东-ios-插屏',
        placeId: '3deb82e5513adb7d',
        impress: 1070,
        historyImpress: 1117,
        unitRevenue: 54.2,
        historyUnitRevenue: 16.91,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 50.65,
        historyEcpm: 15.14,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 234.54
      },
      {
        appId: '',
        appName: '知乎',
        os: 1,
        placeName: '知乎-安卓-发布&我的激励视频',
        placeId: 'ba7b9cbc14fd036d',
        impress: 92,
        historyImpress: 105,
        unitRevenue: 2.74,
        historyUnitRevenue: 2.48,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 29.78,
        historyEcpm: 23.62,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 26.08
      },
      {
        appId: '',
        appName: '抖音',
        os: 1,
        placeName: '抖音-安卓-激励视频',
        placeId: 'ea3f691985f47eba',
        impress: 2515817,
        historyImpress: 2544350,
        unitRevenue: 72280,
        historyUnitRevenue: 57684.22,
        dau: 0,
        historyDau: 0,
        deu: 0,
        historyDeu: 0,
        ecpm: 28.73,
        historyEcpm: 22.67,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 26.73
      }
    ]
  },
  // ARPUDAU
  arpudauMockData: {
    code: 200,
    msg: 'success',
    data: [{
        appId: '',
        appName: '抖音',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 6600.19,
        historyUnitRevenue: 3456.72,
        dau: 13256,
        historyDau: 13211,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0.4979,
        historyArpDau: 0.2617,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 90.26,
        isBind: 0
      },
      {
        appId: '',
        appName: '京东',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 138.85,
        historyUnitRevenue: 54.07,
        dau: 578,
        historyDau: 568,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0.2402,
        historyArpDau: 0.0952,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 152.31,
        isBind: 0
      },
      {
        appId: '',
        appName: '抖音',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 72280,
        historyUnitRevenue: 57684.22,
        dau: 478032,
        historyDau: 478186,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0.1512,
        historyArpDau: 0.1206,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 25.37,
        isBind: 0
      },
      {
        appId: '',
        appName: '快手',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 9565.96,
        historyUnitRevenue: 7149.69,
        dau: 97333,
        historyDau: 95089,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0.0983,
        historyArpDau: 0.0752,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 30.72,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 7130.43,
        historyUnitRevenue: 3900.56,
        dau: 74799,
        historyDau: 78068,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0.0953,
        historyArpDau: 0.05,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 90.6,
        isBind: 0
      }
    ]
  },
  // ARPUDEU
  arpudeuMockData: {
    code: 200,
    msg: 'success',
    data: [{
        appId: '',
        appName: '抖音',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 6600.19,
        historyUnitRevenue: 3456.72,
        dau: 0,
        historyDau: 0,
        deu: 12360,
        historyDeu: 12329,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0.534,
        historyArpDeu: 0.2804,
        ratio: 90.44,
        isBind: 0
      },
      {
        appId: '',
        appName: '京东',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 138.85,
        historyUnitRevenue: 54.07,
        dau: 0,
        historyDau: 0,
        deu: 479,
        historyDeu: 483,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0.2899,
        historyArpDeu: 0.1119,
        ratio: 159.07,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 7130.43,
        historyUnitRevenue: 3900.56,
        dau: 0,
        historyDau: 0,
        deu: 40605,
        historyDeu: 41335,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0.1756,
        historyArpDeu: 0.0944,
        ratio: 86.02,
        isBind: 0
      },
      {
        appId: '',
        appName: '抖音',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 72280,
        historyUnitRevenue: 57684.22,
        dau: 0,
        historyDau: 0,
        deu: 465220,
        historyDeu: 464947,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0.1554,
        historyArpDeu: 0.1241,
        ratio: 25.22,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 6302.94,
        historyUnitRevenue: 6041.1,
        dau: 0,
        historyDau: 0,
        deu: 54022,
        historyDeu: 56464,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0.1167,
        historyArpDeu: 0.107,
        ratio: 9.07,
        isBind: 0
      }
    ]
  },
  // DAU
  dauMockData: {
    code: 200,
    msg: 'success',
    data: [{
        appId: '',
        appName: '抖音',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 478032,
        historyDau: 478186,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: -0.03,
        isBind: 0
      },
      {
        appId: '',
        appName: '闲鱼',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 472762,
        historyDau: 493099,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: -4.12,
        isBind: 0
      },
      {
        appId: '',
        appName: '快手',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 97333,
        historyDau: 95089,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 2.36,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 83186,
        historyDau: 84875,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: -1.99,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 74799,
        historyDau: 78068,
        deu: 0,
        historyDeu: 0,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: -4.19,
        isBind: 0
      }
    ]
  },
  // DEU
  deuMockData: {
    code: 200,
    msg: 'success',
    data: [{
        appId: '',
        appName: '抖音',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 0,
        historyDau: 0,
        deu: 465220,
        historyDeu: 464947,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 0.06,
        isBind: 0
      },
      {
        appId: '',
        appName: '快手',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 0,
        historyDau: 0,
        deu: 97018,
        historyDeu: 94944,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 2.18,
        isBind: 0
      },
      {
        appId: '',
        appName: '淘宝',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 0,
        historyDau: 0,
        deu: 68878,
        historyDeu: 68096,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: 1.15,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 1,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 0,
        historyDau: 0,
        deu: 54022,
        historyDeu: 56464,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: -4.32,
        isBind: 0
      },
      {
        appId: '',
        appName: '小红书',
        os: 0,
        impress: 0,
        historyImpress: 0,
        unitRevenue: 0,
        historyUnitRevenue: 0,
        dau: 0,
        historyDau: 0,
        deu: 40605,
        historyDeu: 41335,
        ecpm: 0,
        historyEcpm: 0,
        arpDau: 0,
        historyArpDau: 0,
        arpDeu: 0,
        historyArpDeu: 0,
        ratio: -1.77,
        isBind: 0
      }
    ]
  }
}
