export const mockData = {
  code: 200,
  msg: 'success',
  data: {
    topBrand: [{
        name: 'APPLE',
        value: 1000
      },
      {
        name: 'HUAWEI',
        value: 800
      },
      {
        name: 'VIVO',
        value: 700
      },
      {
        name: 'OPPO',
        value: 300
      },
      {
        name: 'HONOR',
        value: 200
      },
      {
        name: '其他',
        value: 400
      }
    ],
    otherBrand: [{
        name: 'ZTE',
        value: 1000
      },
      {
        name: 'TCL',
        value: 800
      },
      {
        name: 'LGE',
        value: 700
      },
      {
        name: '360',
        value: 300
      },
      {
        name: 'HTC',
        value: 200
      }
    ]
  }
}
