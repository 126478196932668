<template>
  <div class="company-revenue-container">
    <a-spin :spinning="spinning">
      <div class="revenue-wrapper">
        <div class="revenue-item header">
          <div class="company-name">公司</div>
          <div class="progress-box">收入</div>
        </div>
        <div class="revenue-item item" v-for="(item, index) in revenueList" :key="index">
          <div class="company-name">
            <div class="name-box" :title="item.company">{{ item.company }}</div>
          </div>
          <div class="progress-box">
            <a-tooltip :title="`收入：${moneyType}${numFormat(item.unitRevenue, 3, '')}`">
              <a-progress
                status="active"
                :strokeColor="colorList[index]"
                :percent="+((item.unitRevenue / unitRevenueSum) * 100).toFixed(2)"
                size="small"
              />
            </a-tooltip>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { dashboardCompany } from '@/api/dashboard'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
import { revenueMockData } from './mockData'
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    modelType: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      spinning: false,
      colorList: ['#3de4a9', '#3b78ff', '#7451de', '#bd58f7', '#77a5eb'],
      revenueList: [],
      unitRevenueSum: 0
    }
  },
  computed: {
    ...mapState({
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥',
      isMock: (state) => state.user.isMock
    })
  },
  created () {},
  mounted () {},
  destroyed () {},
  watch: {
    query: {
      handler (val, oldVal) {
        if (oldVal && val.dateStart === oldVal.dateStart && val.dateEnd === oldVal.dateEnd) return
        this.$nextTick(() => {
          this.getRevenueData()
        })
      },
      immediate: true,
      deep: true
    },
    isMock: {
      handler () {
        this.getRevenueData()
      }
    }
  },
  methods: {
    numFormat,
    async getRevenueData () {
      this.spinning = true
      let resp
      if (+this.isMock === 0) {
        resp = await dashboardCompany(this.query)
      } else {
        resp = revenueMockData
      }
      const { data = {} } = resp
      this.revenueList = data.list || []
      this.unitRevenueSum = data.unitRevenueSum || []
      this.spinning = false
    }
  }
}
</script>

<style lang="less" scoped>
.company-revenue-container {
  height: 100%;
  position: relative;
}
</style>
<style lang="less">
.company-revenue-container {
  .ant-spin-nested-loading {
    height: 100%;
    position: relative;
    .ant-spin-container {
      width: 100%;
      height: 100%;
      .revenue-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .revenue-item {
          flex-grow: 1;
          height: 0;
          position: relative;
          display: flex;
          text-align: center;
          border-bottom: 1px solid #eee;
          .company-name {
            width: 50%;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .progress-box {
            flex-grow: 1;
            width: 0;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          &.header {
            max-height: 40px;
            align-items: center;
            .progress-box,
            .company-name {
              color: #666;
            }
          }
          &.item {
            .company-name {
              color: rgba(0, 0, 0, 0.65);
              font-size: 13px;
              justify-content: flex-start;
              .name-box {
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
</style>
