<template>
  <div class="home-container" :style="{'font-size': fontSize + 'px'}">
    <DefaultLayout>
      <template #upperLeft>
        <setButton @handleToSetModel="handleToSetModel" pos="upperLeft" :modelType="2" />
        <component :is="modelsInfo.upperLeft" :modelType="2" pos="upperLeft" />
        <!-- <component :is="'TopEquipment'" :modelType="2" pos="upperLeft" /> -->
      </template>
      <template #lowerLeft>
        <setButton @handleToSetModel="handleToSetModel" pos="lowerLeft" :modelType="1" />
        <component :is="modelsInfo.lowerLeft" pos="lowerLeft" :modelType="1" />
        <!-- <component :is="'TopEquipment'" pos="lowerLeft" :modelType="1" /> -->
      </template>
      <template #upperRight>
        <setButton @handleToSetModel="handleToSetModel" pos="upperRight" :modelType="2" />
        <component :is="modelsInfo.upperRight" pos="upperRight" :modelType="2" />
      </template>
      <template #lowerRight>
        <setButton @handleToSetModel="handleToSetModel" pos="lowerRight" :modelType="1" />
        <component :is="modelsInfo.lowerRight" pos="lowerRight" :modelType="1" />
      </template>
    </DefaultLayout>
    <setModelModel
      @changeModel="changeModel"
      :visible="changeModelVisible"
      :models="modelsInfo"
      :modelInfo="currentModelInfo"
      @modalCancel="changeModelVisible = false"
    />
  </div>
</template>

<script>
import setButton from './components/setbutton.vue'
import setModelModel from './components/setModelModal.vue'
import { mapState } from 'vuex'
import DefaultLayout from './layouts/default.vue'
import autoFontSize from '@/mixins/autoFontSize'

const path = require('path')
const files = require.context('./models', true, /\.vue$/)
const models = {}
const names = []
// 组件导入
files.keys().forEach((key) => {
  const name = path.basename(key, '.vue')
  names.push(name)
  models[name] = files(key).default || files(key)
})
export default {
  name: 'Home',
  components: { DefaultLayout, setButton, setModelModel, ...models },
  mixins: [autoFontSize],
  data () {
    return {
      changeModelVisible: false,
      currentModelInfo: {
        id: undefined,
        position: undefined,
        type: 0
      },
      modelsInfo: {
        upperLeft: 'pieChartPlat',
        lowerLeft: 'barChartApp',
        upperRight: 'operationLog',
        lowerRight: 'pieChartPosition'
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (+this.isFirstLogin === 1) {
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
              <div class="method-modal">
                <h4>当前页面所使用的数据为模拟数据，可在首页看板页面关闭!</h4>
                <div>
                  <img style='width:100%' src={require('@/assets/images/dashboard/guide.png')}/>
                </div>
              </div>
          )
        },
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$store.dispatch('changeIsFirstLogin', 0)
          this.$store.dispatch('changeIsMock', 1)
        },
        onCancel: () => {
          this.$store.dispatch('changeIsFirstLogin', 0)
          this.$store.dispatch('changeIsMock', 0)
        },
        icon: () => {
          return ''
        },
        class: 'method-modal-wrapper',
        width: 600
      })
    }
  },
  computed: {
    ...mapState({
      sysUserDashboardSetting: (state) => state.user.sysUserDashboardSetting,
      isFirstLogin: (state) => state.user.isFirstLogin
    })
  },
  watch: {
    sysUserDashboardSetting: {
      handler (val) {
        this.modelsInfo = JSON.parse(JSON.stringify(val.layoutMap))
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 点击修改按钮
    handleToSetModel (pos, type) {
      this.currentModelInfo.position = pos
      this.currentModelInfo.id = this.modelsInfo[pos]
      this.currentModelInfo.type = type
      this.changeModelVisible = true
    },
    // 修改模块
    changeModel ({ position, modelId }) {
      this.modelsInfo[position] = modelId
      const sysUserDashboardSetting = {
        ...this.sysUserDashboardSetting,
        layoutMap: this.modelsInfo
      }
      this.$store.dispatch('changeSysUserDashboardSetting', sysUserDashboardSetting)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.home-container .model_wrapper:hover .set-button {
  opacity: 1;
  height: 2.14em;
  padding: 0.35em 0.57em 0.35em 0.71em;
}
</style>
