<template>
  <div class="set-button" @click="$emit('handleToSetModel', pos, modelType)" style="font-size: 14px">
    <a-icon type="setting" />
  </div>
</template>

<script>
export default {
  props: {
    pos: {
      default: '',
      type: String
    },
    modelType: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="less" scoped>
.set-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
  z-index: 99;
  background: @primary-color;
  border-radius: 0 0 0 50%;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  opacity: 0;
  transition: all 0.4s;
  box-shadow: -2px 2px 8px 2px #aaa;
  height: 0px;
}
</style>
