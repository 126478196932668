<template>
  <div class="pie-chart-container">
    <div class="control">
      <HomeTitle :title="'Top广告样式'" :borderColor="'#EF6666'" />
      <!-- <a-radio-group class="date_radio" size="small" v-model="datetype" @change="onChange" :disabled="spinning">
        <a-radio-button value="b"> 昨天 </a-radio-button>
        <a-radio-button value="c"> 近7天 </a-radio-button>
        <a-radio-button value="d"> 近30天 </a-radio-button>
      </a-radio-group> -->
      <div class="date-picker-wrapper">
        <a-range-picker
          style="width: 120px"
          format="MM-DD"
          :placeholder="['Start', 'End']"
          v-model="date"
          :allowClear="false"
          :disabled="+isMock === 1"
          :disabledDate="disabledDate"
          @change="changeDate"
          size="small"
          ref="datepicker"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :align="{
            offset: ['-50px', '0px']
          }"
        />
      </div>
    </div>
    <a-spin :style="{ flexGrow: '1' }" :spinning="spinning">
      <div class="chart" ref="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../../components/homeTitle.vue'
import { pieChartData } from '@/api/dashboard'
import option from './options'
import { numFormat } from '@/utils/dealNumber'
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
import { mockData } from './mockData'
import * as echarts from 'echarts'
export default {
  components: { HomeTitle },
  data () {
    return {
      spinning: false,
      date: [],
      query: {
        code: 'unitRevenue',
        dimension: '3',
        endDate: '2022-08-22',
        startDate: '2022-08-16'
      },
      option: {},
      colorList: ['#3de4a9', '#3b78ff', '#7451de', '#bd58f7', '#77a5eb'],
      myEcharts: null
      // datetype: 'b'
    }
  },
  props: {
    modelType: {
      default: 0,
      type: Number
    }
  },
  watch: {
    isMock: {
      handler () {
        this.getChartsData()
      }
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  mixins: [mixDate],
  created () {
    this.option = JSON.parse(JSON.stringify(option))
    this.setDate(this.initDate(1, 1))
    if (this.modelType !== 1) {
      this.option.legend.top = 'center'
      this.option.legend.right = '5%'
      this.option.legend.orient = 'vertical'
      this.option.series[0].radius = ['50%', '80%']
      this.option.series[0].center = ['38%', '50%']
    }
  },
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null)
    this.initChart()
    window.addEventListener('resize', this.getSize)
    this.getChartsData()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    changeDate (date) {
      this.query.startDate = date[0].format('YYYY-MM-DD')
      this.query.endDate = date[1].format('YYYY-MM-DD')
      this.date = date
      this.getChartsData()
    },
    // 不可选时间
    disabledDate (current) {
      return (current && current < this.initDate(31, 0).start) || current >= this.initDate(1, 0).start
    },
    setDate (obj) {
      const { start, end, endDate, startDate } = obj
      this.query.startDate = startDate
      this.query.endDate = endDate
      this.date = [start, end]
    },
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    },
    async getChartsData () {
      this.spinning = true
      let resp
      if (+this.isMock === 0) {
        resp = await pieChartData(this.query)
      } else {
        resp = mockData
      }
      const sum = resp.data.unitRevenueSum
      this.option.tooltip.formatter = (params) => {
        return (
          params.marker +
          '样式：' +
          params.name +
          '<br/>&nbsp;&nbsp;&nbsp;收益：' +
          numFormat(params.data.value, 3, '') +
          '<br/>&nbsp;&nbsp;&nbsp;占比：' +
          (!params.data.value ? 0 : sum !== 0 ? ((params.data.value / sum) * 100).toFixed(2) : 0) +
          '%'
        )
      }
      this.option.series[0].itemStyle = {
        borderRadius: [5, 5, 5, 5],
        borderColor: '#fff',
        borderWidth: 2,
        color: (params) => {
          const colorList = [
            {
              c1: 'rgba(183, 105, 247, 1)',
              c2: 'rgba(138, 89, 251, 1)'
            },
            {
              c1: 'rgba(95, 233, 196, 1)',
              c2: 'rgba(62, 219, 223, 1)'
            },
            {
              c1: 'rgba(249, 169, 110, 1)',
              c2: 'rgba(248, 123, 104, 1)'
            },
            {
              c1: 'rgba(250, 84, 124, 1)',
              c2: 'rgba(247, 65, 108, 1)'
            },
            {
              c1: 'rgba(22, 178, 246, 1)',
              c2: 'rgba(31, 125, 238, 1)'
            },
            {
              c1: 'rgba(248, 202, 116, 1)',
              c2: 'rgba(252, 159, 74, 1)'
            }
          ]
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              // 左、下、右、上
              offset: 0,
              color: colorList[params.dataIndex].c1
            },
            {
              offset: 1,
              color: colorList[params.dataIndex].c2
            }
          ])
        }
      }
      this.option.series[0].data = resp.data.list
        ? resp.data.list.map((item, i) => {
            return {
              value: item.unitRevenue,
              name: item.positionName
            }
          })
        : [
            {
              value: 0,
              name: ''
            }
          ]
      this.spinning = false
      this.initChart()
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';
.pie-chart-container {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    .date-picker-wrapper {
      line-height: 25px;
      vertical-align: middle;
      margin-top: 8px;
      .el-icon-date {
        font-size: 18px;
        line-height: 25px;
        margin-right: 5px;
        color: #999;
        vertical-align: middle;
      }
    }
  }
  .ant-spin-nested-loading {
    flex-grow: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-box-flex-grow: 1; /* OLD - iOS 6-, Safari 3.1-6 */
    height: 0;
    position: relative;
    .ant-spin-container {
      height: 100%;
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.pie-chart-container {
  .aRadioBtn();
}
</style>
