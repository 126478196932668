export const mockData = {
  code: 200,
  msg: 'success',
  data: {
    incomeSum: -9031.81,
    unitRevenueSum: 102208.83,
    dauSum: 0,
    list: [
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '',
        sourceName: '',
        positionName: '激励视频',
        positionId: 5,
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        key: '5',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: -6931.65,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 45990.86,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '',
        sourceName: '',
        positionName: '开屏',
        positionId: 1,
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        key: '1',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: -765.28,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 35076.496,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '',
        sourceName: '',
        positionName: '插屏',
        positionId: 3,
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        key: '3',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: -1334.88,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 11138.058,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '',
        sourceName: '',
        positionName: '原生自渲染',
        positionId: 4,
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        key: '4',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 5002.84,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      },
      {
        date: '2023-05-31',
        appName: '',
        placeName: '',
        groupName: '',
        platName: '',
        sourceName: '',
        positionName: '原生模版',
        positionId: 6,
        testGroupName: '',
        platAccountName: '',
        platPlaceId: '',
        key: '6',
        dau: 0,
        deu: 0,
        startApp: 0,
        newLogin: 0,
        aipau: 0,
        aipu: 0,
        cacheTime: 0,
        income: 0,
        estimatedRevenueEcpm: 0,
        request: 0,
        requestFilledRate: 0,
        response: 0,
        impress: 0,
        cacheImpress: 0,
        impressRate: 0,
        impressRequestRate: 0,
        click: 0,
        clickRate: 0,
        advRequest: 0,
        advResponse: 0,
        advRequestFilledRate: 0,
        permeability: 0,
        advResponseTime: 0,
        trafficRespTime: 0,
        display: 0,
        rdImpress: 0,
        revenuePercentage: 0,
        rdIncome: 0,
        impStartApp: 0,
        startAppDau: 0,
        impFrequency: 0,
        newUserRate: 0,
        ecpm: 0,
        mediaUnitRevenue: 0,
        arpu: 0,
        impressionGap: 0,
        clickGap: 0,
        unitRevenue: 5000.58,
        unitImpression: 0,
        unitImpressionRate: 0,
        unitEcpm: 0,
        unitClick: 0,
        unitClickRate: 0,
        unitCtr: 0,
        unitRequest: 0,
        unitResponse: 0,
        unitFilledRate: 0,
        placePrice: 0,
        triggerImpress: 0,
        configNum: 0,
        advTriggerRate: 0,
        impressSuccessRate: 0,
        askPriceRequest: 0,
        askPriceResponse: 0,
        askPrice: 0,
        winNum: 0,
        askPriceFilledRate: 0,
        bidSuccessRate: 0,
        index: 0,
        arpDeu: 0,
        cacheImpressRate: 0,
        ratio: 0
      }
    ]
  }
}
