export const mockData = {
  code: 200,
  msg: 'success',
  data: {
    income: 62662.41,
    lastHourincome: 61255.42,
    yesterDayIncome: 51306.42,
    incomeRatio: 19.39,
    ecpm: 25.59,
    lastHourEcpm: 25.49,
    yesterdayEcpm: 21.71,
    ecpmRatio: 17.41,
    request: 3268819,
    lastHourRequest: 3201680,
    yesterDayRequest: 3123061,
    requestRatio: 2.52,
    response: 2667346,
    lastHourResponse: 2614843,
    responseFilledRatio: 81.6,
    yesterDayResponse: 2548019,
    yesterdayResponseFilledRatio: 81.59,
    responsedRatio: 0.1,
    impress: 2448540,
    lastHourImpress: 2403353,
    yesterDayImpress: 2363311,
    impressRatio: 1.69,
    impressFilledRatio: 91.8,
    lastHourImpressFilledRatio: 91.91,
    yesterdayFilledImpressRatio: 92.75,
    impressFilledRatio2: -0.91,
    dau: 897137,
    lastHourResponseFilledRatio: 81.67
  }
}
