<template>
  <div class="default-layout-container" :style="{'font-size': fontSize + 'px'}">
    <!-- eslint-disable vue/require-component-is -->
    <div class="home-header">
      <RealTimeCard />
    </div>
    <div class="home-main">
      <div class="home-main-top">
        <div class="home-main-top-left model_wrapper">
          <!-- <slot name="upperLeft" /> -->
          <div class="wrapper">
            <div class="wrapper-inner">
              <slot name="upperLeft" />
            </div>
          </div>
        </div>
        <div class="home-main-top-center"><HomeBoard /></div>
        <div class="home-main-top-right model_wrapper">

          <div class="wrapper">
            <div class="wrapper-inner">
              <slot name="upperRight" />
            </div>
          </div>
        </div>
      </div>
      <div class="home-main-bottom">
        <div class="home-main-bottom-left model_wrapper">
          <div class="wrapper">
            <div class="wrapper-inner">
              <slot name="lowerLeft" />
            </div>
          </div>
        </div>
        <div class="home-main-bottom-center"><DataIndicator /></div>
        <div class="home-main-bottom-right model_wrapper">
          <div class="wrapper">
            <div class="wrapper-inner">
              <slot name="lowerRight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RealTimeCard from '../realTimeCard'
import HomeBoard from '../homeBoard'
import DataIndicator from '../dataIndicator'
import autoFontSize from '@/mixins/autoFontSize'

export default {
  mixins: [autoFontSize],
  components: { RealTimeCard, HomeBoard, DataIndicator }
}
</script>

<style lang="less" scoped>
// 两侧宽度
@siderWidth: 26%;
.default-layout-container {
  display: flex;
  flex-direction: column;
  padding: 0.71em;
  box-sizing: border-box;
  border-radius: 0.35em;
  // min-width: 1280px;
  .home-header {
    position: relative;
    padding: 0 0 0.71em 0;
    min-width: 1180px;
  }
  .home-main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .home-main-top{
      margin-bottom: 0.71em;
    }
    .home-main-top,
    .home-main-bottom {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      width: 100%;
      position: relative;
      .home-main-top-left,
      .home-main-top-right,
      .home-main-bottom-left,
      .home-main-bottom-right {
        width: @siderWidth;
        position: relative;
        .wrapper .wrapper-inner{
          width: 100%;
          height: 100%;
          inset: 0;
          position: absolute;
        }
      }
      .home-main-top-center, .home-main-bottom-center {
        width: 0;
        flex-grow: 1;
        padding: 0 0.71em;
      }
      .home-main-top-left, .home-main-top-right {
        .wrapper{
          width: 100%;
          padding-bottom: 80%;
          position: relative;
        }

      }
      .home-main-bottom-left, .home-main-bottom-right {
        .wrapper{
          width: 100%;
          padding-bottom: 100%;
        }
      }
    }
  }
}
</style>
